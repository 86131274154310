<template>
  <cs-page class="bg-white views-product-index">
    <div class="top-bg text-center" v-if="getBrowser == 'pc'">
      <h1>微海翘财—财务管理SaaS云平台</h1>
      <p>
        面向连锁服务企业的一款专业财务管理SaaS应用
        <br />
        帮助财务人员管理财务内容，高效、智能提升连锁服务企业财务管理水平
      </p>
    </div>
    <div class="top-bg text-center" v-if="getBrowser == 'phone'">
      <h1 style="">微海翘财 <br />财务管理SaaS云平台</h1>
      <p>
        面向连锁服务企业的一款专业财务管理SaaS应用
        帮助财务人员管理财务内容，高效、智能提升连锁服务企业财务管理水平
      </p>
    </div>
    <div class="comment mx-auto" v-if="getBrowser == 'pc'">
      <div class="comment-info">
        <a-avatar
          :src="formModel.headUrl"
          style=" height: 24px;width: 24px;margin-top: -3px;margin-right: 5px;border: 1px solid #eee;"
        />
        <span style="color: #1d1e21"
          >{{ formModel.userRealName | encryText }}：</span
        >
        <span style="color: #797d86">{{
          formModel.content | intercept(40, true)
        }}</span>
      </div>
    </div>
    <div
      v-if="getBrowser == 'phone'"
      style="background: #f7f8fa; height: 110px; width:100%;margin-bottom: 30px"
    >
      <van-swipe type="card" :loop="false"  @change="onChange" ref="swiper">
        <van-swipe-item
          v-for="(item, index) in lunboList"
          :key="index"
          class="swipBox"
        > 
        <div style="width:100%;">
            <p style="display: flex; align-items: center; margin-top: 20px;">
            <a-avatar
              :src="formModel.headUrl"
              class="avatar"
            />
            <span class="nameSpan">
              {{ item.userRealName }}:
            </span>
           </p>
            <p class="contentP">
              <span class="contentSapn">
                {{ item.content }}</span
              >
            </p>
        </div>
          
        </van-swipe-item>
      </van-swipe>
    </div>

    <a-spin
      tip="产品加载中..."
      :spinning="list.length === 0"
      class="mx-auto"
      v-if="getBrowser == 'pc'"
      style="width: 1120px"
    >
      <a-row :gutter="40">
        <a-col :span="8" v-for="(item, index) in list" :key="index">
          <ProductItem :config="item" style="margin-bottom: 40px" />
        </a-col>
      </a-row>
    </a-spin>
    <a-spin
      tip="产品加载中..."
      :spinning="list.length === 0"
      class="mx-auto"
      style="width: 100%"
      v-if="getBrowser == 'phone'"
    >
      <a-row>
        <a-col v-for="(item, index) in list" :key="index">
          <ProductItem
            :config="item"
            :showQuery="showShenqing"
            style="margin-bottom: 40px"
          />
        </a-col>
      </a-row>
    </a-spin>
    <van-popup
      v-model="showShenqing"
      closeable
      v-if="getBrowser == 'phone'"
      style="border-radius: 10px 10px 0 0"
      position="bottom"
      @close="onClose"
    >
      <a-row>
        <a-col :span="1"> </a-col>
        <a-col :span="22">
          <h2 class="title">零元预约</h2>
          <h2 class="title">体验智能财务SaaS系统</h2>
          <a-form-model
            ref="form"
            :model="formModel2"
            :rules="formRules"
            @submit="submitClick"
            @submit.native.prevent
            style="
              margin: 0 auto;
              background: white;
              padding: 20px 16px;
              border-radius: 20px;
            "
          >
            <a-form-model-item prop="customerCompany">
              <a-input
                v-model="formModel2.customerCompany"
                size="large"
                class="inputApply"
                placeholder="公司名称"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item prop="customerName">
              <a-input
                v-model="formModel2.customerName"
                size="large"
                class="inputApply"
                placeholder="姓名"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="customerPhone">
              <a-input
                v-model="formModel2.customerPhone"
                size="large"
                class="inputApply"
                placeholder="手机号"
                :maxLength="11"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item prop="captcha">
              <a-input
                v-model="formModel2.captcha"
                placeholder="验证码"
                size="large"
                class="inputApply"
                :maxLength="6"
                style="width: 100%"
              >
                <template slot="suffix">
                  <cs-countdown
                    text="获取验证码"
                    ref="CsCountdown"
                    class="cursor-pointer"
                    :validate="validateCode"
                    style="color: #00a497"
                    v-slot="{ time }"
                    >{{ time }}s</cs-countdown
                  >
                </template>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                class="btn-submit"
                size="large"
                block
                style="font-weight: 500"
                html-type="submit"
              >
                提交申请
              </a-button>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>
      <div
        class="apply-toast position-fixed top-50 start-50 translate-middle d-flex"
        v-if="applyResult > 0"
      >
        <template v-if="applyResult == 1">
          <a-icon
            type="check-circle"
            style="font-size: 54px; color: #00a497; margin-right: 20px"
            theme="filled"
          />
          <div>
            <h3>申请已提交</h3>
            <span>我们将尽快和您联系~</span>
          </div>
        </template>
        <template v-else>
          <a-icon
            type="info-circle"
            style="font-size: 54px; color: #feae39; margin-right: 20px"
            theme="filled"
          />
          <div>
            <h3>您的申请已受理</h3>
            <span>24小时内请勿重复提交~</span>
          </div>
        </template>
      </div>
    </van-popup>
  </cs-page>
</template>
<script>
import { components } from "@/plugins/utils";
export default {
  ...components({
    product: ["ProductItem"],
  }),
  data() {
    return {
      formModel: {
        userRealName: "",
        content: "",
        headUrl: "",
      },

      formModel2: {
        customerCompany: "",
        customerName: "",
        customerPhone: "",
        captcha: "",
        timer: null,
      },
      applyResult: 0,
      transition: true,
      list: [],
      lunboList: [],
      timer: null,
      showShenqing: false,
      formRules: {
        customerCompany: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入公司名称"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        customerName: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入姓名"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        customerPhone: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号码"));
              }
              if (!this.api.validate.isPhoneNumber(value)) {
                return callback(new Error("请输入正确的手机号码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        captcha: [
          {
            validator: (rule, value, callback) => {
              if (!value || value.length != 6) {
                return callback(new Error("请输入6位验证码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
      },
    };
  },
  provide() {
    return {
      fatherMethod: this.changeShengqing,
    };
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    initData() {
      this.api.service
        .fpc_oapi_product_selectCarefullyChosenProduct({}, {})
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0);
          if (data.length === 0) {
            this.$message.error("当前没有推荐产品");
          }
          this.list = data.map((ele, index) => {
            let list = [];
            if (ele.productTipOne) {
              list.push(ele.productTipOne);
            }
            if (ele.productTipTwo) {
              list.push(ele.productTipTwo);
            }
            if (ele.productTipThree) {
              list.push(ele.productTipThree);
            }
            if (ele.productTipFour) {
              list.push(ele.productTipFour);
            }
            return {
              id: ele.productId,
              name: ele.productName,
              price: ele.price,
              desc: ele.productDesc,
              list,
              className: `product-bg-${index % 6}`,
            };
          });
        });
      // 读取评价内容
      this.api.service
        .fpc_oapi_evaluate_selectEvaluatePublished({}, {})
        .then(async ({ code, data, msg }) => {
          this.lunboList = data;
          await this.api.toast({ code, msg }, 0);
          let index = 0;
          this.nextEvaluate(data, index);
          this.timer = setInterval(() => {
            index += 1;
            if (index >= data.length) {
              index = 0;
            }
            this.nextEvaluate(data, index);
          }, 5000);
        });
    },
    onChange(e){
      if(e == this.lunboList.length -1){
        setTimeout(()=>{
          this.$refs.swiper.swipeTo(0);
        },5000)
       
      }
      
    },
    onClose() {
      this.resetData();
      this.$refs.form.resetFields();
    },
    changeShengqing() {
      this.showShenqing = true;
    },
    nextEvaluate(data, index) {
      let { headUrl, content, userRealName } = data[index];
      if (!headUrl) {
        headUrl = this.api.getPublicFile(
          `img/avatar/${Math.round(Math.random() * 8)}.png`
        );
      }
      this.formModel.userRealName = userRealName;
      this.formModel.headUrl = headUrl;
      this.formModel.content = content;
      if(this.getBrowser == 'pc'){
        this.api.animateCSS(this.$el.querySelector(".comment-info"), "fadeIn");
      }
    },
    // 获取验证码
    async validateCode() {
      const { customerPhone } = this.formModel2;
      if (!customerPhone || !this.api.validate.isPhoneNumber(customerPhone)) {
        this.$message.error("请输入正确的手机号");
        throw new Error("请输入正确的手机号");
      }
      const res = await this.api.service.fpc_oapi_customer_getCaptcha(
        {},
        { customerPhone }
      );
      if (res.code == "20801") {
        return this.showApplyMsg(2);
      }
      await this.api.toast(res, 0);
    },
    submitClick() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          const res = await this.api.service.fpc_oapi_customer_submit(
            this.formModel2,
            {}
          );
          if (res.code == "20801") {
            return this.showApplyMsg(2);
          }
          await this.api.toast(res, 0);
          this.showApplyMsg(1);
        }
      });
    },
    // 重置数据
    resetData() {
      this.formModel2.customerCompany = "";
      this.formModel2.customerName = "";
      this.formModel2.customerPhone = "";
      this.formModel2.captcha = "";
    },
    showApplyMsg(result) {
      this.applyResult = result;
      this.resetData();
      setTimeout(() => {
        this.applyResult = -1;
      }, 2000);
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px) and (max-width: 1024px) {
  .views-product-index {
    background: #f7f8fa !important;
    .top-bg {
      padding: 40px 40px 20px 40px;
      // background: url(../../../public/img/product_list_top_bg.jpg) center center;
      // background-size: cover;
      h1 {
        text-align: left;
        color: #041334;
        font-family: "Source Han Sans CN";
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 35px;
      }

      p {
        text-align: left;
        width: 315px;
        color: #666c78;
        text-align: justify;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .title {
      text-align: center;
      margin-top: 1rem;
      font-size: 18px;
      font-weight: 700;
    }
    .inputApply {
      background: #f7f8fa;
      color: #3e4c5a;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    /deep/ .ant-input-affix-wrapper .ant-input:not(:last-child) {
      background: #f7f8fa;
      color: #3e4c5a;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .apply-toast {
      width: 94%;
      position: absolute;
      top: 8%;
      margin-left: 3%;
      padding: 32px;
      background: #ffffff;
      border-radius: 15px;
      h3 {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #333333;
        font-weight: 500;
      }
      span {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #999999;
        font-weight: 400;
      }
    }
    .comment {
      width: 100%;
      height: auto;
      line-height: 28px;
      // background: #f7f8fa;
      border-radius: 14px;
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 0 20px;
    }
    .swipBox{
      background: white;
      border-radius: 8px;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .avatar{
        height: 24px;
        width: 24px;
        margin-top: -3px;
        margin-right: 5px;
        border: 1px solid #eee;
      }
      .nameSpan{
        color: #666c78;
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left:5px;
      }
      .contentP{
         height: 40px; 
         width: 92%;
        .contentSapn{
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          align-self: stretch;
          color: #041334;
          text-align: justify;
          font-family: 'PingFang SC';
          font-size: 14px;
          padding-right:20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      
    }
   
  }
}
@media screen and (min-width: 1024px) {
  .views-product-index {
    .top-bg {
      padding: 40px;
      background: url(../../../public/img/product_list_top_bg.jpg) center center;
      background-size: cover;
      h1 {
        font-size: 28px;
        color: #202121;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        line-height: 21px;
        color: #797d86;
      }
    }
    .comment {
      width: 855px;
      height: 28px;
      line-height: 28px;
      background: #f7f8fa;
      border-radius: 14px;
      margin-top: 20px;
      margin-bottom: 40px;
      padding: 0 20px;
      overflow: hidden;
    }
  }
}
</style>
